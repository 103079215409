;import $ from 'jquery';
window.$ = window.jQuery = $;
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import 'jquery.scrollto';
import '../vendor/ba-debug.min.js';

(function($){
//------------------------------------------------------------------------------------------------------------------------
    // Page constructor

    // Page constructor
    window.Page = {

//------------------------------------------------------------------------------------------------------------------------
    // Global variables

        // GTM
        dataLayer: window.dataLayer || [],

        // CSRF
        csrf_token: $('meta[name="csrf-token"]').attr('content'),

        // Responsive breakpoints
        mobileMaxWidth: 767.98,
        tabletMaxWidth: 1023.98,

        // scrollTo
        scrollTo: {
            axis: 'y',
            duration: 500,
            offset: {
                left: 0,
                top: -112
            }
        },

        // Modal
        modal: {
            messages: false,
            title: false
        },

        // Animation
        animation: 300,
        hideAnimation: 3000,
        playControlsTimer: null,

//------------------------------------------------------------------------------------------------------------------------
    // Application

        // Set layout
        setLayout: function(){
            // Load videos
            window.Page.loadVideos();

            // Load scrollTo
            window.Page.loadScrollTo();

            // Load modals
            window.Page.loadModals();

            // Load GTM
            window.Page.loadGTM();
        },

        // Layout loaded
        layoutLoaded: function(){
            // Start manifesto video
            window.Page.startVideo($('#video'));
        },

        // Resize layout
        resizeLayout: function(){
            // Sticky navbar
            window.Page.stickyHeader();
        },

//------------------------------------------------------------------------------------------------------------------------
    // Videos

        // Start video
        startVideo: function($container){
            var $video = $('video', $container);
            if($video.length){
                $video.prop('muted', true);
                var promise = $video[0].play();

                // Is autoplay enabled?
                if(promise !== undefined){
                    // It is
                    promise.then(_ => {
                        window.Page.hidePlayControls($container);
                    })
                    // It is not
                    .catch(error => {
                        window.Page.showPlayControls($container);
                    });
                }
            }
        },

        // Load videos
        loadVideos: function(){
            $('.video').each(function(){
                var $container = $(this);
                var $video = $('video', $container);

                if($video.length){
                    // Clicked on mute button
                    $container.on('click', '.btn-mute', function(e){
                        e.preventDefault();
                        if($video.prop('muted')){
                            window.Page.unmuteVideo($video, $container);
                        }
                        else {
                            window.Page.muteVideo($video, $container);
                        }
                    });

                    // Clicked on replay button
                    $container.on('click', '.btn-video, .btn-replay:visible', function(e){
                        e.preventDefault();
                        window.Page.replayVideo($video, $container);
                        window.Page.hidePlayControls($container);
                    });

                    // Clicked on play button
                    $container.on('click', '.btn-play:visible', function(e){
                        e.preventDefault();
                        window.Page.playVideo($video, $container);
                        window.Page.hidePlayControls($container);
                    });

                    // Clicked on pause button
                    $container.on('click', '.btn-pause:visible', function(e){
                        e.preventDefault();
                        window.Page.pauseVideo($video, $container);
                        window.Page.showPlayControls($container);
                    });

                    // Show video controls when moving mouse over video
                    $container.on('mousemove', function(e){
                        window.Page.showPlayControls($container);
                    });

                    // Hide video controls when mouse leaves video
                    $container.on('mouseleave', function(e){
                        window.Page.hidePlayControls($container);
                    });

                    // Video is paused
                    $video.on('pause', function(e){
                        window.Page.hidePause($container);
                    });

                    // Video started to play
                    $video.on('play', function(e){
                        window.Page.showPause($container);
                    });
                }
            });
        },

        // Play video
        playVideo: function($video, $container){
            window.Page.unmuteVideo($video, $container);
            $video[0].play();
        },

        // Restart video
        replayVideo: function($video, $container){
            $video[0].pause();
            if($video[0].readyState === 4){
                $video[0].currentTime = 0;
            }
            window.Page.unmuteVideo($video, $container);
            $video[0].play();
        },

        // Puase video
        pauseVideo: function($video, $container){
            $video[0].pause();
        },

        // Mute video
        muteVideo: function($video, $container){
            var $btnMute = $('.btn-mute', $container);
            $video.prop('muted', true);
            $btnMute.addClass('muted');
        },

        // Unmute video
        unmuteVideo: function($video, $container){
            var $btnMute = $('.btn-mute', $container);
            $video.prop('muted', false);
            $btnMute.removeClass('muted');
        },

        // Show pause button, hide play button
        showPause: function($container){
            $('.btn-pause', $container).show();
            $('.btn-play', $container).hide();
        },

        // Hide pause button, show play button
        hidePause: function($container){
            $('.btn-pause', $container).hide();
            $('.btn-play', $container).show();
        },

        // Show video controls
        showPlayControls: function($container){
            if(window.Page.playControlsTimer){
                clearTimeout(window.Page.playControlsTimer);
            }
            window.Page.fadeInPlayControls($container);

            // Hide video controls after a time of inactivity
            window.Page.playControlsTimer = setTimeout(window.Page.fadeOutPlayControls, window.Page.hideAnimation, $container);
        },

        // Hide video controls
        hidePlayControls: function($container){
            if(window.Page.playControlsTimer){
                clearTimeout(window.Page.playControlsTimer);
            }
            window.Page.fadeOutPlayControls($container);
        },

        // Animation for showing controls
        fadeInPlayControls: function($container){
            $('.play-controls:hidden', $container).css({ display: 'flex' }).hide().fadeIn(window.Page.animation);
        },

        // Animation for hiding controls
        fadeOutPlayControls: function($container){
            $('.play-controls', $container).fadeOut(window.Page.animation);
        },

//------------------------------------------------------------------------------------------------------------------------
    // scrollTo

        // Sticky navbar
        stickyHeader: function(){
            // There's no sticky navbar when header is fixed
            if($('header').hasClass('fix')){
                return false;
            }

            // Height of the navbar
            var navbarHeight = $('.navbar-collapse', 'header').outerHeight();

            // Position of the navbar's bottom
            var checkpointHideAt = $('.hero', 'header').outerHeight() + navbarHeight;

            // Checkpoints for animation
            var checkpointHidden = checkpointHideAt + navbarHeight;
            var checkpointMinSection = $('section.checkpoint-min').offset();
            var checkpointMin = checkpointMinSection.top;

            // Get sticky when menu is out of view
            if($(window).scrollTop() > checkpointHideAt){
                $('header').addClass('sticky');
            }
            else {
                $('header').removeClass('sticky');
            }

            // Show sticky animation, if we've scrolled over the checkpoint with at least the height of the menu and the 'selected' section is already visible
            if($(window).scrollTop() > checkpointHidden && $(window).scrollTop() + $(window).height() > checkpointMin){
                $('header').addClass('in');
            }
            else {
                $('header').removeClass('in');
            }
        },

        // Load scrollTo
        loadScrollTo: function(){
            // CTA
            $(document).on('click', 'a[href^="#"], a[href^="' + window.location.origin + window.location.pathname + '#"]', function(e){
                e.preventDefault();
                window.Page.scrollToHash(this.hash);

                // Open tab if there's one
                if(!$(this).hasClass('tab-link') && $(this.hash).hasClass('tab-pane')){
                    bootstrap.Tab.getInstance($('[data-bs-target="' + this.hash + '"]').get(0)).show();
                }
            });

            // On load
            if(document.readyState === 'complete'){
                window.Page.scrollToHash(window.location.hash);
            }
            $(window).on('load', function(e){
                window.Page.scrollToHash(window.location.hash);
            });
        },

        // Scroll to content
        scrollToHash: function(hash, params){
            if(!/^\#[0-9a-z_-]+$/gi.test(hash) || !$(hash).is(':visible')){
                return false;
            }

            // Override settings
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, window.Page.scrollTo, params);

            // Close navbar on mobile view if open
            var $navbar = $('.navbar-collapse', 'header');
            if($navbar.hasClass('show')){
                // Scroll to content when navbar is closed
                $navbar.one('hidden.bs.collapse', function(e){
                    $(window).scrollTo(hash, settings);
                });
                // Close navbar
                var bsCollapse = new bootstrap.Collapse($navbar.get(0), { toggle: false });
                bsCollapse.hide();
            }
            // Scroll to content immediately
            else {
                $(window).scrollTo(hash, settings);
            }

            // Show hash
            window.location.hash = hash;
        },

//------------------------------------------------------------------------------------------------------------------------
    // GTM Event

        // Load GTM
        loadGTM: function(){
            // CTA
            $(document).on('click', '[data-event="click"]', function(e){
                window.Page.sendEvent($(this).data('event-action'), $(this).data('event-category'), $(this).data('event-label'));
            });

            // On load
            $('[data-event="callback"]').each(function(i, target){
                window.Page.sendEvent($(target).data('event-action'), $(target).data('event-category'), $(target).data('event-label'));
            });
        },

        // GTM Event
        sendEvent: function(action, category, label){
            window.Page.dataLayer.push({
                event: action,
                eventAction: action,
                eventCategory: category,
                eventLabel: label
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // Modals

        // Load modals
        loadModals: function(){
            // Load modals
            $('.modal').each(function(i, target){
                var modal = new bootstrap.Modal($(target).get(0));

                // Show default modals
                if($(target).attr('data-show') == 'true'){
                    modal.show();
                }

                // Modal has been open
                $(target).on('shown.bs.modal', function(e){
                    // Bootstrap bugfix: losing autofocus in modals
                    $(':input[autofocus]:first', $(this)).focus();
                });
            });
        },

        // Show modal
        showModal: function(id, params){
            // Override settings
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, window.Page.modal, params);
            if(!id) return false;

            // Check if modal exists
            var $modal = $('#modal-' + id);
            if(!$modal.length){
                return false;
            }
            var modal = bootstrap.Modal.getInstance($modal.get(0));
            if(!modal){
                modal = new bootstrap.Modal($modal.get(0));
            }

            // Substitute parameters
            if(params !== false){
                $('.modal-title', $modal).html(settings.title ? settings.title : '');
                $('.modal-body', $modal).empty();
                if(typeof settings.errors !== 'undefined'){
                    window.Page.appendTextAsPs($('.modal-body', $modal), settings.errors);
                }
                else {
                    window.Page.appendTextAsPs($('.modal-body', $modal), settings.messages);
                }
            }

            // Show modal
            modal.show();
        },

        // Show error messages
        showError: function(messages, errors){
            window.Page.showModal('error', { title: 'An error occured.', messages: messages, errors: errors });
        },

        // Append text array as paragraphs
        appendTextAsPs: function($target, text){
            if(!$target.length){
                return false;
            }

            // Convert variable to array
            text = typeof text !== 'undefined' ? text : [];
            if(text.constructor === String){
                text = [text];
            }
            else if(text.constructor === Array){
                text = text;
            }
            else if(typeof text === 'object'){
                var arr = [];
                $.each(text, function(i, value){
                    arr.push(value);
                });
                text = arr;
            }
            else {
                text = [];
            }

            // Append text
            $.each(text, function(i, line){
                $target.append($('<p />').html(line));
            });
        },

//------------------------------------------------------------------------------------------------------------------------

    };

//------------------------------------------------------------------------------------------------------------------------
    // Document ready

    // Document ready
    $(function(){
        // Set layout
        window.Page.setLayout();

        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window loaded

    // Window loaded
    $(window).on('load', function(e){
        // Resize layout
        window.Page.resizeLayout();

        // Layout loaded
        window.Page.layoutLoaded();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window resized or orientation changed

    // Window resized or orientation changed
    $(window).on('orientationchange resize', function(e){
        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window scrolled

    // Window scrolled
    $(window).on('scroll', function(e){
        // Resize layout
        window.Page.resizeLayout();

        // Show video controls on scroll
        window.Page.showPlayControls();
    });

//------------------------------------------------------------------------------------------------------------------------
})(jQuery);
